import React from "react";
const CA = '0x3f542A196EfcE4434abBaA8426f36Ec3073aE585';
const PAIR = '0xf4efb37c2cab21a8fc111263d702b73b19d68699';
const X = 'https://x.com/gar_base';
const TELE = 'https://t.me/gar_channel';
interface ActionButtonProps {
  text?: string;
  imageSrc?: string;
  link?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text, imageSrc, link }) => {
  const baseClasses =
    "flex justify-center items-center px-6 py-4 rounded-lg shadow-sm max-md:px-5";
  const classes = text
    ? `${baseClasses} cursor-pointer text-lg font-bold leading-6 text-center text-black bg-amber-500`
    : `${baseClasses} cursor-pointer bg-amber-50 border-4 border-yellow-600 border-solid`;

  return (
    <div className={classes} onClick={()=> {window.open(link)}}>
      {text ? (
        text
      ) : (
        <img
          loading="lazy"
          src={imageSrc}
          alt=""
          className="aspect-square w-[26px]"
        />
      )}
    </div>
  );
};

const ActionButtons: React.FC = () => {
  const buttons = [
    { text: "BUY NOW",
     link: `https://app.uniswap.org/swap?outputcurrency=${CA}&chain=base&utm_source=web&utm_medium=cta` },
    {
      link:  `https://www.dextools.io/app/en/base/pair-explorer/${PAIR}`,
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/def90d4419b497f3afb3247868217c61506afe0c42cfb5df0415341b8fc98567?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
    },
    {
      link: `https://dexscreener.com/base/${PAIR}`,
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/0ff53af0b45b10b0b086e971e19ba5fbe8074691dcc874c8b050463a370309d1?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
    },
    {
      link: X,
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/f2e1505963c510847c6e832ad4b1bf7e1b043e30a8486ad5f871b4af76d9e2bd?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
    },
    {
      link: TELE,
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e9431a3863245ccd556d30dc2f96b71d93654b05097d6b00258105e3b04e4843?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
    },
  ];

  return (
    <div className="flex flex-wrap gap-4 justify-center content-center mt-3 max-w-full w-[653px]">
      {buttons.map((button, index) => (
        <ActionButton key={index} {...button} />
      ))}
    </div>
  );
};

export default ActionButtons;
