import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const Collection = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const swiperRef = useRef<any>(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  useEffect(() => {
    const handleNavigation = (swiper: any) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    };

    const swiperElement = document.querySelector(".swiper-our-clients");
    if (swiperElement) {
      const swiper = (swiperElement as any).swiper;
      if (swiper) {
        handleNavigation(swiper);
      }
    }
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current;

      const updateActiveIndex = () => {
        setActiveIndex(swiperInstance.realIndex);
      };

      swiperInstance.on("slideChange", updateActiveIndex);

      updateActiveIndex();

      return () => {
        swiperInstance.off("slideChange", updateActiveIndex);
      };
    }
  }, [swiperRef.current]);

  return (
    // max-w-[1260px]
    <section
      ref={ref}
      className="flex-wrap justify-center content-center py-12 w-full max-md:max-w-full pt-0 md:pt-12"
    >
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex grow gap-1 items-center max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-2 w-full md:gap-4">
              <img
                ref={prevRef}
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e61aefa42c81be35eab57c2b22a544e48d2c3cba5371a519b814e93e94a27718?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
                alt=""
                className="cursor-pointer min-w-8 md:min-w-12"
                // className="shrink-0 self-stretch my-auto border-4 border-solid aspect-[0.63] border-yellow-950 fill-amber-500 stroke-[4px] stroke-yellow-950 w-[63px]"
              />
              {/* <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0571c00551603e1e0944a70d7dd08378667965b56454b61f0b19ee208823f6c?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
                alt="Garfield and Odie Collection"
                // className="flex-1 self-stretch w-full border-black border-solid aspect-[1.08] border-[6px] max-md:max-w-full"
              /> */}
              <Swiper
                onInit={(swiper: any) => {
                  swiperRef.current = swiper;
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                breakpoints={{
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 32,
                  },
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                centeredSlides={true}
                loop={true}
                modules={[Navigation, Autoplay]}
                className="swiper-our-clients"
                slidesPerView={1}
                spaceBetween={12}
              >
                <SwiperSlide className="swiper-our-clients-item">
                  <img
                    className="rounded-md"
                    src="/images/slide/1-01 1.png"
                    alt="slide-1"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-our-clients-item">
                  <img
                    className="rounded-md"
                    src="/images/slide/2-01 1.png"
                    alt="slide-1"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-our-clients-item">
                  <img
                    className="rounded-md"
                    src="/images/slide/3-01 1.png"
                    alt="slide-1"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-our-clients-item">
                  <img
                    className="rounded-md"
                    src="/images/slide/4-01 1.png"
                    alt="slide-1"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-our-clients-item">
                  <img
                    className="rounded-md"
                    src="/images/slide/5-01 1.png"
                    alt="slide-1"
                  />
                </SwiperSlide>
                <SwiperSlide className="swiper-our-clients-item">
                  <img
                    className="rounded-md"
                    src="/images/slide/6-01 1.png"
                    alt="slide-1"
                  />
                </SwiperSlide>
              </Swiper>
              <img
                ref={nextRef}
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a958c4fd037786949299166638c779c8f067bc3533eddafa194df0b2908dac4?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
                alt=""
                className="cursor-pointer min-w-8 md:min-w-12"
                // className="shrink-0 self-stretch my-auto border-4 border-solid aspect-[0.63] border-yellow-950 fill-amber-500 stroke-[4px] stroke-yellow-950 w-[63px]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto text-xl text-black max-md:mt-10 max-md:max-w-full">
            <h2 className="text-7xl leading-6 text-center text-amber-500 border-amber-500 border-solid border-[3px] max-md:max-w-full max-md:text-4xl max-md:leading-3 border-transparent">
              Collection<span className="text-5xl"> ({activeIndex + 1}/6:)</span>
            </h2>
            <p className="mt-4 max-md:max-w-full">
              Hey! Garfield and Odie - the purrfectly playful meme coin inspired
              by everyone's favorite lasagna-loving cat and his loyal canine
              companion! Garfield and Odie Coin captures the humorous and
              whimsical essence of these beloved characters, offering a fun and
              engaging way to dive into the world of cryptocurrency. Our mission
              is to create a vibrant and light-hearted community where fans can
              enjoy unique rewards, share laughs, and celebrate the joy of
              investing. With Garfield and Odie Coin, we're bringing the charm
              of classic comic strips to the modern crypto space, making finance
              as entertaining as it is rewarding. Join us on this delightful
              adventure and let's make crypto fun again!
            </p>
            <p className="mt-1 max-md:max-w-full">
              Hey! Garfield and Odie - the purrfectly playful meme coin inspired
              by everyone's favorite lasagna-loving cat and his loyal canine
              companion!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Collection;
