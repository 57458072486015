import React from "react";
import { HeaderProps } from "./MainContent";

const Header: React.FC<HeaderProps> = ({ sectionRefs, scrollToTop }) => {
  const scrollToSection = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navItems = ["Home", "About GOC", "How to buy", "Collection"];

  const handleScrool = (item: string) => {
    switch (item) {
      case "About GOC":
        scrollToSection(sectionRefs.aboutRef);
        break;
      case "How to buy":
        scrollToSection(sectionRefs.howToBuyRef);
        break;
      case "Collection":
        scrollToSection(sectionRefs.collectionRef);
        break;
      default:
        scrollToTop();
        break;
    }
  };

  return (
    // max-w-[1260px]
    <header className="flex justify-center items-center px-16 py-4 w-full text-3xl font-bold text-black uppercase bg-yellow-600 bg-opacity-0 tracking-[2.24px] max-md:px-5 max-md:max-w-full">
      <nav className="flex gap-5 justify-between w-full max-w-[1008px] max-md:flex-wrap max-md:max-w-full">
        {navItems.map((item, index) => (
          <div
            key={index}
            className={
              index === 0 ? "text-amber-500 cursor-pointer" : "cursor-pointer"
            }
            onClick={() => handleScrool(item)}
          >
            {item}
          </div>
        ))}
      </nav>
    </header>
  );
};

export default Header;
