import React, { useRef } from "react";
import Header from "./Header";
import Title from "./Title";
import ContactInfo from "./ContactInfo";
import About from "./About";
import HowToBuy from "./HowToBuy";
import Collection from "./Collection";
import Footer from "./Footer";
import ActionButtons from "./ActionButton";

export interface HeaderProps {
  sectionRefs: {
    aboutRef: React.RefObject<HTMLDivElement>;
    howToBuyRef: React.RefObject<HTMLDivElement>;
    collectionRef: React.RefObject<HTMLDivElement>;
  };
  scrollToTop: () => void;
}

const MainContent: React.FC = () => {
  const aboutRef = useRef<HTMLDivElement>(null);
  const howToBuyRef = useRef<HTMLDivElement>(null);
  const collectionRef = useRef<HTMLDivElement>(null);

  const sectionRefs = {
    aboutRef,
    howToBuyRef,
    collectionRef,
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <main className="flex flex-col justify-center bg-amber-50">
      <div className="flex flex-col justify-center items-center px-5 pt-6 w-full bg-amber-50 max-md:max-w-full px-3">
        <Header sectionRefs={sectionRefs} scrollToTop={scrollToTop} />
        <Title />
        <ContactInfo />
        <ActionButtons />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c371a2ba1b260a82e32db151670eeec022cfc795e4e4aa9c14fc1556b5f60f3a?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
          alt="Garfield and Odie banner"
          className="self-stretch mt-8 w-full aspect-[4.55] max-md:max-w-full"
        />
        <About ref={aboutRef} />
        <HowToBuy ref={howToBuyRef} />
        <Collection ref={collectionRef} />
        <Footer />
      </div>
    </main>
  );
};

export default MainContent;
