import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="w-full">
      {/* max-w-[1260px] */}
      <div className="flex flex-wrap gap-5 justify-center content-center px-20 py-6 mt-24 w-full text-6xl tracking-widest text-amber-50 bg-amber-500 rounded-xl shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        <div className="my-auto border-amber-50 border-solid border-[3px] max-md:max-w-full max-md:text-4xl border-transparent">
          Garfield And Odie <br /> Trading is not boring
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/16aa6f8c2c2662b29c6365c4fabf502cf1ed2f66a5b8ebaebe07d60ea776ca4d?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
          alt="GAR logo"
          className="shrink-0 max-w-full aspect-[0.83] w-[247px]"
        />
      </div>
      {/* Continuing from where we left off: */}
      <div className="flex justify-center items-center self-stretch px-16 py-4 mt-24 w-full text-lg tracking-widest text-center text-amber-50 bg-amber-500 max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-2 max-md:flex-wrap">
          <div className="max-md:max-w-full">
            2024 Oddie & Garfield COINS | All Rights Reserved
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/92e5ff493f712d41389c96e5bd2d32869131bcbbdfc6c8adc3683e6948e42474?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
            alt=""
            className="shrink-0 my-auto w-2 aspect-[0.88] fill-white"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
