import React from 'react';

const Title: React.FC = () => {
  return (
    <h1 className="mt-40 text-8xl leading-6 text-center text-amber-500 border-amber-500 border-solid border-[3px] max-md:mt-10 max-md:max-w-full max-md:text-4xl border-transparent">
      Garfield And Odie
    </h1>
  );
};

export default Title;