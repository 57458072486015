import React, { useState } from "react";
import SweetAlert2, { SweetAlert2Props } from "react-sweetalert2";
const ContactInfo: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);
  const contractAddr = "0x3f542A196EfcE4434abBaA8426f36Ec3073aE585";

  const swalProps: SweetAlert2Props = {
    title: "Well done!",
    text: "You had compied Contract Address!",
    icon: "success",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Good job!",
    didClose: () => {
      console.log("Alert closed");
      // Automatically reset the showAlert state to true after it closes
      setShowAlert(false);
    },
  };
  return (
    <div className="flex gap-4 justify-center pr-2 mt-11 text-2xl tracking-widest leading-8 text-center whitespace-nowrap w-full text-yellow-950 max-md:flex-wrap max-md:mt-10">
      <div className="flex justify-center text-ellipsis max-md:max-w-full flex-wrap mb-5 md:mb-0">
        <span>$GOC CONTRACT:</span>
        <span className="font-bold text-ellipsis overflow-hidden">
          {contractAddr}
        </span>
        <div
          onClick={() => {
            // Make copy action
            navigator.clipboard.writeText(contractAddr);
            setShowAlert(true);
          }}
        >
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/da793093ef5fce231ebf9b7958cf43a8b24e032f6130c94f46b6dc37c47de483?apiKey=f9f711ac72684fe79caf31b99cec2d64&"
            alt=""
            className="shrink-0 my-auto aspect-[0.89] w-[26px] cursor-pointer"
          />
        </div>
      </div>

      <SweetAlert2 {...swalProps} show={showAlert} />
    </div>
  );
};

export default ContactInfo;
