import React from 'react';

const About = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <section ref={ref} className="flex-wrap content-center self-stretch p-20 w-full max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[56%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col justify-center self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            <h2 className="text-7xl leading-6 text-amber-500 border-amber-500 border-solid border-[3px] max-md:max-w-full max-md:text-4xl border-transparent">
              ABOUT:
            </h2>
            <p className="mt-6 text-base text-black max-md:max-w-full">
              Hey! Garfield and Odie - the purrfectly playful meme coin inspired by everyone's favorite lasagna-loving cat and his loyal canine companion! Garfield and Odie Coin captures the humorous and whimsical essence of these beloved characters, offering a fun and engaging way to dive into the world of cryptocurrency. Our mission is to create a vibrant and light-hearted community where fans can enjoy unique rewards, share laughs, and celebrate the joy of investing. With Garfield and Odie Coin, we're bringing the charm of classic comic strips to the modern crypto space, making finance as entertaining as it is rewarding. Join us on this delightful adventure and let's make crypto fun again!
            </p>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[44%] max-md:ml-0 max-md:w-full">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/03532ed102ed87ed301b1350c9abb417ba24fb892cf7ec82b82dbbc3dbcc10f8?apiKey=f9f711ac72684fe79caf31b99cec2d64&" alt="Garfield and Odie illustration" className="grow w-full border border-amber-50 border-solid aspect-[0.72] max-md:mt-10 max-md:max-w-full" />
        </div>
      </div>
    </section>
  );
});

export default About;