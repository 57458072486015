import React from 'react';

interface StepProps {
  imageSrc: string;
  title: string;
  description: string;
}

const Step: React.FC<StepProps> = ({ imageSrc, title, description }) => {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center max-md:mt-10 max-md:max-w-full">
        <div className="flex overflow-hidden relative flex-col items-start px-5 pt-20 pb-8 w-full border-black border-solid border-[6px] min-h-[500px] max-md:px-5 max-md:max-w-full">
          <img loading="lazy" src={imageSrc} alt="" className="object-cover absolute inset-0 size-full" />
          <div className="flex relative flex-col py-4 pr-px pl-3.5 mt-72 max-w-full bg-white rounded-md shadow-sm w-[425px] max-md:mt-10">
            <h3 className="text-2xl tracking-wide leading-7 text-orange-500 uppercase border border-orange-500 border-solid border-transparent">
              {title}
            </h3>
            <p className="mt-4 text-base tracking-tight text-black">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const HowToBuy= React.forwardRef<HTMLDivElement>((props, ref) => {
  const steps = [
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9a62f3937c5f9b979ed28413145cb4e6e66f7616d449565df6dd713812cc7f0?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
      title: "Add Base to MetaMask",
      description: "Download & install the Metamask Wallet either from the app store on your phone of the brower extension for desktop"
    },
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e58a8dd4fb05304f74254e52bdfd6b984cd44bdc13985c8e2877a954c826a126?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
      title: "load up eth",
      description: "Head over to brigde.base.org, connect connect your wallet on the Ethereum blockchain. Choose how much ETH you would like to bridge from the erc20 network to Base. Remember to leave enough for gas Fees!"
    },
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e862bac6a5e27662521a1e8457ee573c038efc02edf68e308095c62763fccfe5?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
      title: "buy",
      description: "Head over to Uniswap and paste the GOC contract address listed on this website to swap your ETH"
    },
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/5411f31fe2f06874ab3012845c4256aa723becaefe2ee64497ef0a87a9d82157?apiKey=f9f711ac72684fe79caf31b99cec2d64&",
      title: "Add to your wallet",
      description: "Now all you have to do is add the GOC contract address to your Metamask Wallet for your GOC tokens to show. Welcome aboard!"
    }
  ];

  return (
    <section ref={ref} className="flex flex-col self-stretch p-20 w-full max-md:px-5 max-md:max-w-full">
      <h2 className="mx-2.5 text-7xl leading-6 text-center text-amber-500 border-amber-500 border-solid border-[3px] max-md:max-w-full max-md:text-4xl border-transparent">
        HOW TO BUY:{" "}
      </h2>
      <div className="mx-2.5 mt-12 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {steps.slice(0, 2).map((step, index) => (
            <Step key={index} {...step} />
          ))}
        </div>
      </div>
      <div className="mx-2.5 mt-16 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {steps.slice(2).map((step, index) => (
            <Step key={index + 2} {...step} />
          ))}
        </div>
      </div>
    </section>
  );
});

export default HowToBuy;